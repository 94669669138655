import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// @material-ui/icons

import Grid from "@material-ui/core/Grid"

import Card from "../../components/Card/Card.js"
import CardHeader from "../../components/Card/CardHeader.js"
import CardBody from "../../components/Card/CardBody.js"

import styles from "../../jss/portfolio.js"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const useStyles = makeStyles(styles)

const getImages = graphql`
  {
    goPro: file(relativePath: { eq: "website-development-one.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    sunrise: file(relativePath: { eq: "sunrise.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    empire: file(relativePath: { eq: "Empire.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    tapers: file(relativePath: { eq: "torontotapers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    harbour: file(relativePath: { eq: "harbourHVAC.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    foxwood: file(relativePath: { eq: "foxwood.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

export default function ProductSection() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div>
      <div className={classes.section}>
        <Grid container justify="center">
          <Grid item xs={12} sm={12} md={8} align="center">
            <h2 className={classes.title} align="center">
              Our Work
            </h2>
            <p className={classes.description}>
              These are some of the websites that we designed and launched for
              our valued clients.
            </p>
          </Grid>
        </Grid>
        <div style={{ marginTop: 60 }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            alignContent="center"
            justify="center"
          >
            <Grid item xs={12} sm={12} md={4} lg={10}>
              <Card product className={classes.cardHover}>
                <CardHeader image className={classes.cardHeaderHover}>
                  <a href="https://www.goproplumbing.ca/">
                    <Img
                      fluid={data.goPro.childImageSharp.fluid}
                      alt="Toronto-Web-Designer"
                    />
                  </a>
                </CardHeader>
                <CardBody>
                  <h4 className={classes.portfolioTitle}>
                    <a href="https://www.goproplumbing.ca/">GoPro Plumbing</a>
                  </h4>
                  <p className={classes.cardProductDescription}>
                    Professional plumbers in Toronto. We helped with the web
                    design and ongoing SEO optimization for GoPo Plumbing's
                    website.
                  </p>
                </CardBody>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={10}>
              <Card product className={classes.cardHover}>
                <CardHeader image className={classes.cardHeaderHover}>
                  <a href="https://torontotapers.ca/">
                    <Img
                      fluid={data.tapers.childImageSharp.fluid}
                      alt="Toronto-Web-Designer"
                    />
                  </a>
                </CardHeader>
                <CardBody>
                  <h4 className={classes.portfolioTitle}>
                    <a href="https://torontotapers.ca/">Toronto Tapers</a>
                  </h4>
                  <p className={classes.cardProductDescription}>
                    Professional drywall contractors in Toronto. We designed,
                    developed and SEO optimized Toronto Tapers' website.
                  </p>
                </CardBody>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={10}>
              <Card product className={classes.cardHover}>
                <CardHeader image className={classes.cardHeaderHover}>
                  <a href="https://www.harbourhvac.com/">
                    <Img
                      fluid={data.harbour.childImageSharp.fluid}
                      alt="Toronto-Web-Designer"
                    />
                  </a>
                </CardHeader>
                <CardBody>
                  <h4 className={classes.portfolioTitle}>
                    <a href="https://www.harbourhvac.com/">Harbour HVAC </a>
                  </h4>
                  <p className={classes.cardProductDescription}>
                    Professional HVAC Technicians in Toronto. We helped with the
                    web design and ongoing SEO optimization.
                  </p>
                </CardBody>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={10}>
              <Card product className={classes.cardHover}>
                <CardHeader image className={classes.cardHeaderHover}>
                  <a href="https://foxwoodelectric.ca/">
                    <Img
                      fluid={data.foxwood.childImageSharp.fluid}
                      alt="Toronto-Web-Designer"
                    />
                  </a>
                </CardHeader>
                <CardBody>
                  <h4 className={classes.portfolioTitle}>
                    <a href="https://foxwoodelectric.ca/">Foxwood Electric</a>
                  </h4>
                  <p className={classes.cardProductDescription}>
                    Electrical contracting company in Toronto. We've helped
                    design and optimize their website for higher Google ranking.
                  </p>
                </CardBody>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={10}>
              <Card product className={classes.cardHover}>
                <CardHeader image className={classes.cardHeaderHover}>
                  <a href="https://www.sunrisemind.ca/">
                    <Img
                      fluid={data.sunrise.childImageSharp.fluid}
                      alt="Toronto-Web-Designer"
                    />
                  </a>
                </CardHeader>
                <CardBody>
                  <h4 className={classes.portfolioTitle}>
                    <a href="https://www.sunrisemind.ca/">Sunrise Mind</a>
                  </h4>
                  <p className={classes.cardProductDescription}>
                    Online E-commerce store using Shopify to manage content and
                    items. We've helped them improve web traffic and online
                    sales.
                  </p>
                </CardBody>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={10}>
              <Card product className={classes.cardHover}>
                <CardHeader image className={classes.cardHeaderHover}>
                  <a href="https://www.empireframing.ca/">
                    <Img
                      fluid={data.empire.childImageSharp.fluid}
                      alt="Toronto-Web-Designer"
                    />
                  </a>
                </CardHeader>
                <CardBody>
                  <h4 className={classes.portfolioTitle}>
                    <a href="https://www.empireframing.ca/">Empire Framing</a>
                  </h4>
                  <p className={classes.cardProductDescription}>
                    Framing contracting company in Toronto. We've helped design
                    and optimize their website for higher Google ranking.
                  </p>
                </CardBody>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}
