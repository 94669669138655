import {
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,
    hexToRgb,
    blackColor
} from "./nextjs-material-kit.js";

const cardHeaderStyle = {
    cardHeader: {
        borderRadius: "3px",
        padding: "1rem 15px",
        marginLeft: "15px",
        marginRight: "15px",
        marginTop: "-30px",
        border: "0",
        marginBottom: "0"
    },
    cardHeaderPlain: {
        marginLeft: "0px",
        marginRight: "0px"
    },
    warningCardHeader,
    successCardHeader,
    dangerCardHeader,
    infoCardHeader,
    primaryCardHeader,

    cardHeaderImage: {
        position: "relative",
        padding: "0",
        zIndex: "1",
        "& img": {
            width: "100%",
            borderRadius: "6px",
            pointerEvents: "none",
            boxShadow: "0 5px 15px -8px rgba(" +
                hexToRgb(blackColor) +
                ", 0.24), 0 8px 10px -5px rgba(" +
                hexToRgb(blackColor) +
                ", 0.2)"
        },
        "& a": {
            display: "block"
        }
    },
};

export default cardHeaderStyle;