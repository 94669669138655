import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import classNames from "classnames"

// core components
import Header from "../components/Header/Header.jsx"
import Footer from "../components/Footer/Footer.jsx"

import Grid from "@material-ui/core/Grid"

import HeaderLinks from "../components/Header/HeaderLinks.jsx"
import Parallax from "../components/Parallax/ParallaxPortfolio.jsx"

import Intro from "../pages-sections/Portfolio/Intro.jsx"
import Contact from "../pages-sections/Portfolio/Contact.jsx"
import styles from "../jss/portfolio.js"
import { GatsbySeo, LogoJsonLd } from "gatsby-plugin-next-seo"

const useStyles = makeStyles(styles)

export default function Maintenance(props) {
  const classes = useStyles()
  const { ...rest } = props
  return (
    <div>
      <LogoJsonLd
        logo="https://storage.googleapis.com/product-image-upload/web-design-toronto.png"
        url="https://atlasagency.ca"
      />
      <GatsbySeo
        title="Atlas Agency | Portfolio | Toronto Web Design"
        canonical="https://atlasagency.ca/portfolio/"
        description="Together we make a difference, these are some of our Toronto web designs we've designed for our valued clients."
        openGraph={{
          type: "website",
          locale: "en_IE",
          description:
            "Web design Toronto based company. We provide web design and SEO services for companies of all sizes. All of our web designs are mobile and desktop optimized making the user experience seamless and quick.",
          url: "https://atlasagency.ca",
          site_name: "Atlas Agency | Web Design Toronto",
          images: [
            {
              url:
                "https://storage.googleapis.com/product-image-upload/toronto-web-design2.png",
              width: 800,
              height: 450,
              alt: "Web Design Toronto",
            },
          ],
        }}
        twitter={{
          cardType: "summary_large_image",
          site: "@AtlasAgencyCA",
        }}
      />
      <Header
        color="transparent"
        brand="Atlas Agency"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 60,
          color: "dark",
        }}
        {...rest}
      />
      <Parallax filter responsive>
        <div className={classes.container} style={{ marginTop: "80px" }}>
          <Grid container>
            <Grid item xs={12} sm={12} md={8}>
              <h1 className={classes.MainTitle}>Our Portfolio</h1>
              <h4>
                Together we make a difference, these are some of the websites
                we've designed for our valued clients.
              </h4>
              <br />
            </Grid>
          </Grid>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Intro />
        </div>
        <div className={classes.container}>
          <Contact />
        </div>
      </div>
      <Footer />
    </div>
  )
}
