import {
  container,
  title,
  section,
  grayColor,
  successColor,
  sectionDark,
  sectionDescription,
  whiteColor,
  hexToRgb,
  sectionDarkMarketing,
  sectionDarkSEO,
  cardTitle
} from "./nextjs-material-kit.js";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },

  description: {
    color: "#000000"
  },

  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#3c4858",
    textDecoration: "none"
  },
  MainTitle: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "white",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow: "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  imageContainer: {
    maxWidth: "340px",
    marginBottom: "200px",
    position: "relative",
    height: "360px",
    "& img": {
      maxWidth: "1040px",
      height: "640px",
      width: "auto",
      position: "absolute",
      left: "-300px",
      top: "-30px"
    }
  },

  imageContainerSEOTablet: {
    maxWidth: "340px",
    marginBottom: "200px",
    position: "relative",
    height: "150px",
    "& img": {
      maxWidth: "1040px",
      height: "380px",
      width: "auto",
      position: "absolute",
      left: "-55px",
      top: "20px"
    }
  },

  imageContainerMobileWeb: {
    maxWidth: "240px",
    marginBottom: "-250px",
    position: "relative",
    height: "360px",
    "& img": {
      maxWidth: "1040px",
      width: "auto",
      height: "200px",
      position: "absolute",
      left: "1px",
      top: "10px"
    }
  },

  imageContainerMobileSEO: {
    maxWidth: "240px",
    marginBottom: "10px",
    position: "relative",
    height: "60px",
    "& img": {
      maxWidth: "1040px",
      width: "auto",
      height: "200px",
      position: "absolute",
      right: "-40px",
      top: "10px"
    }
  },

  imageContainerMobile: {
    maxWidth: "340px",
    marginBottom: "20px",
    position: "relative",
    height: "360px",
    "& img": {
      maxWidth: "1040px",
      width: "auto",
      position: "absolute",
      right: "-50px",
      top: "-400px"
    }
  },

  imageContainerTablet: {
    maxWidth: "1px",
    marginBottom: "400px",
    position: "relative",
    height: "160px",
    "& img": {
      maxWidth: "1040px",
      width: "auto",
      height: "440px",
      position: "absolute",
      right: "-460px",
      top: "20px"
    }
  },


  imageContainerTabletWeb: {
    maxWidth: "1px",
    marginBottom: "400px",
    position: "relative",
    height: "50px",
    "& img": {
      maxWidth: "1040px",
      width: "auto",
      height: "440px",
      position: "absolute",
      right: "-640px",
      top: "20px"
    }
  },

  section: {
    ...section,
    padding: "50px 0px"
  },
  sectionDarkMarketing,
  sectionDarkSEO,

  sectionDark,

  sectionDescription,



  cardTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "3px",
    textAlign: "center"
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: "14px",
    paddingTop: "10px",
    marginBottom: "0",
    marginTop: "0",
    margin: "0"
  },

  stats: {
    color: grayColor[0],
    fontSize: "12px",
    lineHeight: "22px",
    display: "inline-flex",
    "& svg": {
      position: "relative",
      top: "4px",
      width: "16px",
      height: "16px",
      marginRight: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      position: "relative",
      top: "4px",
      fontSize: "16px",
      marginRight: "3px"
    }
  },
  productStats: {
    paddingTop: "7px",
    paddingBottom: "7px",
    margin: "0"
  },

  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: "17px",
    height: "17px"
  },
  price: {
    color: "inherit",
    "& h4": {
      marginBottom: "0px",
      marginTop: "0px"
    }
  },

  hoverCardStyle: {
    cardHover: {
      "&:hover": {
        "& $cardHeaderHover": {
          transform: "translate3d(0, -50px, 0)"
        }
      }
    },

    cardHover: {
      "&:hover": {
        "& $cardHeaderHover": {
          transform: "translate3d(0, -10px, 0)"
        }
      }
    },
    cardHeaderHover: {
      transition: "all 300ms cubic-bezier(0.34, 1.61, 0.7, 1)"
    },
    cardHoverUnder: {
      position: "absolute",
      zIndex: "1",
      top: "-50px",
      width: "calc(100% - 30px)",
      left: "17px",
      right: "17px",
      textAlign: "center"
    }
  },
  portfolioTitle: {
    "& a": {
      color: "black",
    },

    "& a:hover": {
      color: "#525252",

    }
  },




};

export default landingPageStyle;